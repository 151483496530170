import { NotFound, PrivateRoute } from 'components/commons';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const AuthPage = React.lazy(() => import('features/auth/pages/AuthPage'));
const LoginPage = React.lazy(() => import('features/auth/pages/Login'));
const ForgotPage = React.lazy(() => import('features/auth/pages/ForgotPass'));
const ChangePassPage = React.lazy(() => import('features/auth/pages/ChangePass'));

const UserDashboard = React.lazy(() => import('features/user/pages/UserDashboard'));
const RoleList = React.lazy(() => import('features/role/pages/RoleList'));
const DepartmentList = React.lazy(() => import('features/department/pages/DepartmentList'));
const ActionList = React.lazy(() => import('features/action/pages/ActionList'));
const ItemParamList = React.lazy(() => import('features/config/pages/ItemParamList'));
const ConfigList = React.lazy(() => import('features/config/pages/ConfigList'));
const UserList = React.lazy(() => import('features/user/pages/UserList'));
const UserAdd = React.lazy(() => import('features/user/pages/UserAdd'));
const UserEdit = React.lazy(() => import('features/user/pages/UserEdit'));
const CategoryList = React.lazy(() => import('features/category/pages/CategoryList'));
const StoreList = React.lazy(() => import('features/store/pages/StoreList'));
const StoreAdd = React.lazy(() => import('features/store/pages/StoreAdd'));
const StoreEdit = React.lazy(() => import('features/store/pages/StoreEdit'));
const AttributeList = React.lazy(() => import('features/attribute/pages/AttributeList'));
const ProductList = React.lazy(() => import('features/product/pages/ProductList'));
const ProductAdd = React.lazy(() => import('features/product/pages/ProductAdd'));
const ProductEdit = React.lazy(() => import('features/product/pages/ProductEdit'));
const ProductQuantity = React.lazy(() => import('features/product/pages/ProductQuantity'));
const PrintProductList = React.lazy(() => import('features/printProduct/pages/PrintList'));
const PrintProductAdd = React.lazy(() => import('features/printProduct/pages/PrintAdd'));
const PrintProductEdit = React.lazy(() => import('features/printProduct/pages/PrintEdit'));
const PrintProductItem = React.lazy(() => import('features/printProduct/pages/PrintItem'));
const ExchangeList = React.lazy(() => import('features/exchange/pages/ExchangeList'));
const ExchangeAdd = React.lazy(() => import('features/exchange/pages/ExchangeAdd'));
const ExchangeEdit = React.lazy(() => import('features/exchange/pages/ExchangeEdit'));
const ImportList = React.lazy(() => import('features/import/pages/ImportList'));
const ImportAdd = React.lazy(() => import('features/import/pages/ImportAdd'));
const ImportEdit = React.lazy(() => import('features/import/pages/ImportEdit'));
const OrderList = React.lazy(() => import('features/order/pages/OrderList'));
const OrderAdd = React.lazy(() => import('features/order/pages/OrderAdd'));
const OrderEdit = React.lazy(() => import('features/order/pages/OrderEdit'));
const ReportOrderProduct = React.lazy(() => import('features/order/pages/ReportOrderProduct'));
const OrderPrint = React.lazy(() => import('features/order/pages/OrderPrint'));
const ReturnOrderList = React.lazy(() => import('features/returnOrder/pages/ReturnOrderList'));
const ReturnOrderAdd = React.lazy(() => import('features/returnOrder/pages/ReturnOrderAdd'));
const ReturnOrderEdit = React.lazy(() => import('features/returnOrder/pages/ReturnOrderEdit'));
const TransactionList = React.lazy(() => import('features/transaction/pages/TransactionList'));
const ReportTransactionList = React.lazy(() => import('features/transaction/pages/ReportTransactionList'));
const TransactionCompany = React.lazy(() => import('features/transaction/pages/TransactionCompany'));
const TransactionAdd = React.lazy(() => import('features/transaction/pages/TransactionAdd'));
const TransactionEdit = React.lazy(() => import('features/transaction/pages/TransactionEdit'));
const TransactionTypeList = React.lazy(() => import('features/transactionType/pages/TransactionTypeList'));
const BillList = React.lazy(() => import('features/bill/pages/BillList'));
const BillAdd = React.lazy(() => import('features/bill/pages/BillAdd'));
const BillEdit = React.lazy(() => import('features/bill/pages/BillEdit'));
const BillPrint = React.lazy(() => import('features/bill/pages/BillPrint'));
const SizeRangeList = React.lazy(() => import('features/sizeRange/pages/SizeRangeList'));
const PackNumberList = React.lazy(() => import('features/packNumber/pages/PackNumberList'));

export default function RouterView() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AuthPage />}>
                    <Route index element={<LoginPage />} />
                    <Route path="forgot" element={<ForgotPage />} />
                    <Route path="change-password" element={<ChangePassPage />} />
                </Route>

                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            <UserDashboard />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/action"
                    element={
                        <PrivateRoute>
                            <ActionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/item/:type"
                    element={
                        <PrivateRoute>
                            <ItemParamList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/config"
                    element={
                        <PrivateRoute>
                            <ConfigList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/user/add/:type"
                    element={
                        <PrivateRoute>
                            <UserAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/user/edit/:id"
                    element={
                        <PrivateRoute>
                            <UserEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/user/:type"
                    element={
                        <PrivateRoute>
                            <UserList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/role"
                    element={
                        <PrivateRoute>
                            <RoleList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/department"
                    element={
                        <PrivateRoute>
                            <DepartmentList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/category"
                    element={
                        <PrivateRoute>
                            <CategoryList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/store"
                    element={
                        <PrivateRoute>
                            <StoreList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/store/add"
                    element={
                        <PrivateRoute>
                            <StoreAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/store/edit/:id"
                    element={
                        <PrivateRoute>
                            <StoreEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/attribute"
                    element={
                        <PrivateRoute>
                            <AttributeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/product/add"
                    element={
                        <PrivateRoute>
                            <ProductAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/product/edit/:id"
                    element={
                        <PrivateRoute>
                            <ProductEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/product"
                    element={
                        <PrivateRoute>
                            <ProductList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/productQuantity"
                    element={
                        <PrivateRoute>
                            <ProductQuantity />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/printProduct"
                    element={
                        <PrivateRoute>
                            <PrintProductList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/printProduct/add"
                    element={
                        <PrivateRoute>
                            <PrintProductAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/printProduct/edit/:id"
                    element={
                        <PrivateRoute>
                            <PrintProductEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/printProduct/print/:id"
                    element={
                        <PrivateRoute>
                            <PrintProductItem />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/exchange/add/:type/:id?"
                    element={
                        <PrivateRoute>
                            <ExchangeAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/exchange/edit/:id"
                    element={
                        <PrivateRoute>
                            <ExchangeEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/exchange/:type"
                    element={
                        <PrivateRoute>
                            <ExchangeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/import/add/:id?"
                    element={
                        <PrivateRoute>
                            <ImportAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/import/edit/:id"
                    element={
                        <PrivateRoute>
                            <ImportEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/import"
                    element={
                        <PrivateRoute>
                            <ImportList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transactionType/:type"
                    element={
                        <PrivateRoute>
                            <TransactionTypeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order/add/:id?"
                    element={
                        <PrivateRoute>
                            <OrderAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order/edit/:id"
                    element={
                        <PrivateRoute>
                            <OrderEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order/print/:id"
                    element={
                        <PrivateRoute>
                            <OrderPrint />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/order"
                    element={
                        <PrivateRoute>
                            <OrderList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/reportOrderProduct"
                    element={
                        <PrivateRoute>
                            <ReportOrderProduct />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/returnOrder/add/:id?"
                    element={
                        <PrivateRoute>
                            <ReturnOrderAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/returnOrder/edit/:id"
                    element={
                        <PrivateRoute>
                            <ReturnOrderEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/returnOrder"
                    element={
                        <PrivateRoute>
                            <ReturnOrderList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transaction/:type/add/:id?"
                    element={
                        <PrivateRoute>
                            <TransactionAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transaction/edit/:id"
                    element={
                        <PrivateRoute>
                            <TransactionEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transaction/:type"
                    element={
                        <PrivateRoute>
                            <TransactionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/reportTransaction/:type"
                    element={
                        <PrivateRoute>
                            <ReportTransactionList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/transactionCompany"
                    element={
                        <PrivateRoute>
                            <TransactionCompany />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/bill/add/:id?"
                    element={
                        <PrivateRoute>
                            <BillAdd />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/bill/edit/:id"
                    element={
                        <PrivateRoute>
                            <BillEdit />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/bill/print/:id"
                    element={
                        <PrivateRoute>
                            <BillPrint />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/bill"
                    element={
                        <PrivateRoute>
                            <BillList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/sizeRange"
                    element={
                        <PrivateRoute>
                            <SizeRangeList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route
                    path="/packNumber"
                    element={
                        <PrivateRoute>
                            <PackNumberList />
                        </PrivateRoute>
                    }
                ></Route>
                <Route path="not-found" element={<NotFound />}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    );
}
