import Action from 'types/Action';
import { ApiResponse, DataListResponse } from 'types/common';
import http from './http';

const getList = async () => {
    const { data } = await http.get<ApiResponse<DataListResponse<Action>>>(`/action`);
    return data;
};

const getListByUser = async (id: number) => {
    const { data } = await http.get<ApiResponse<DataListResponse<Action>>>(`/user/${id}/actions`);
    return data;
};

const update = async (param: Partial<Action>, id: number) => {
    const { data } =
        id > 0
            ? await http.patch<ApiResponse<number>>(`/action/${id}`, param)
            : await http.post<ApiResponse<number>>('/action', param);
    return data;
};

const deleteItem = async (id: number) => {
    const { data } = await http.delete<ApiResponse<{}>>(`/action/${id}`);
    return data;
};

const ActionService = {
    getList,
    update,
    deleteItem,
    getListByUser,
};

export default ActionService;
