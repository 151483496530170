import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { AUTH_KEYS } from 'constants/auth';
import i18next from 'i18next';
import { find } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { setLangCode } from 'slices/languageSlice';
import { Language } from 'types/common/Item';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

interface ItemLanguage {
    name: string;
    label: string;
    icon: string;
}
const Languages: ItemLanguage[] = [
    { name: Language.VI, label: 'Vietnam', icon: 'vn' },
    { name: Language.EN, label: 'English', icon: 'us' },
];

export default function LanguageSwitch() {
    const [show, setShow] = useState(false);
    const [translateX, setTranslateX] = useState(0);
    const refParent = useRef(null);
    const refChild = useRef(null);

    const [itemLanguage, setItemLanguage] = useState<ItemLanguage>(Languages[0]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const langCode = getLocalStorage(AUTH_KEYS.LANGUAGE_CODE, Language.VI);
        setItemLanguage(find(Languages, { name: langCode }) ?? Languages[0]);
    }, []);

    useLayoutEffect(() => {
        function updateChildPosition() {
            const parentWidth = refParent.current ? refParent.current['offsetWidth'] : 0;
            const childWidth = refChild.current ? refChild.current['offsetWidth'] : 0;
            if (parentWidth > 0 && childWidth > 0) {
                setTranslateX(parentWidth - childWidth);
            }
        }
        window.addEventListener('resize', updateChildPosition);
        updateChildPosition();
        return () => window.removeEventListener('resize', updateChildPosition);
    }, [show]);

    const chooseItem = (item: ItemLanguage) => {
        setShow(false);
        setItemLanguage(item);
        setLocalStorage(AUTH_KEYS.LANGUAGE_CODE, item.name);
        dispatch(setLangCode(item.name));
        i18next.changeLanguage(item.name);
    };

    return (
        <li className="nav-item dropdown dropdown-language" ref={refParent}>
            <a className="nav-link dropdown-toggle" onClick={() => setShow((prevShow) => !prevShow)}>
                <i className={classNames('flag-icon', `flag-icon-${itemLanguage.icon}`)} />
                <span className="selected-language">{itemLanguage.label}</span>
            </a>
            <div
                className={classNames('dropdown-menu dropdown-menu-end', { show })}
                ref={refChild}
                style={{ transform: `translate(${translateX}px, 40px)` }}
            >
                {Languages.map((item) => (
                    <a key={item.name} className="dropdown-item" onClick={() => chooseItem(item)}>
                        <i className={classNames('flag-icon', `flag-icon-${item.icon}`)} /> {item.label}
                    </a>
                ))}
            </div>
        </li>
    );
}
