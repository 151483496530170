import User, { ChangePassword, LoginData, LoginResult, SearchUser } from 'types/User';
import { ApiResponse, DataListResponse } from 'types/common';
import { ItemId } from 'types/common/Item';
import { genQueryString } from 'utils/common';
import http from './http';

const login = async (param: LoginData) => {
    const { data } = await http.post<ApiResponse<LoginResult>>('/auth/login', param);
    return data;
};

const logout = async () => {
    const { data } = await http.post<ApiResponse<{}>>('/auth/logout');
    return data;
};

const forgotPassword = async (email: string) => {
    const { data } = await http.post<ApiResponse<{}>>('/auth/forgotPassword', { email });
    return data;
};

const resetPassword = async (param: ChangePassword) => {
    const { data } = await http.post<ApiResponse<{}>>('/auth/resetPassword', param);
    return data;
};

const changePassword = async (param: ChangePassword) => {
    const { data } = await http.post<ApiResponse<{}>>('/user/changePassword', param);
    return data;
};

const getItem = async (id: number) => {
    const { data } = await http.get<ApiResponse<User>>(`/user/${id}`);
    return data;
};

const getProfile = async () => {
    const { data } = await http.get<ApiResponse<User>>('user/self');
    return data;
};

const getList = async (param: SearchUser) => {
    const { data } = await http.get<ApiResponse<DataListResponse<User>>>(`/user${genQueryString(param)}`);
    return data;
};

const update = async (param: Partial<User>, id: number = 0) => {
    const { data } =
        id > 0
            ? await http.patch<ApiResponse<ItemId>>(`/user/${id}`, param)
            : await http.post<ApiResponse<ItemId>>('/user', param);
    return data;
};

const deleteItem = async (id: number) => {
    const { data } = await http.delete<ApiResponse<{}>>(`/user/${id}`);
    return data;
};
const pushOut = async (id: number) => {
    const { data } = await http.get<ApiResponse<{}>>(`/user/${id}/pushOut`);
    return data;
};

const UserService = {
    login,
    logout,
    forgotPassword,
    changePassword,
    getItem,
    getProfile,
    getList,
    update,
    deleteItem,
    resetPassword,
    pushOut,
};

export default UserService;
