import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import ActionService from 'services/ActionService';
import UserService from 'services/UserService';
import Action from 'types/Action';
import User, { LoginData } from 'types/User';
import i18n from 'utils/i18n';
export interface AuthState {
    user: User | null;
    actions: Action[];
    isLoading: boolean;
    message: string;
}

const initialState: AuthState = {
    user: null,
    actions: [],
    isLoading: false,
    message: '',
};

export const login = createAsyncThunk('auth/login', async (data: LoginData) => UserService.login(data));
export const logout = createAsyncThunk('auth/logout', async () => UserService.logout());
export const getActions = createAsyncThunk('action/getListByUser', async (userId: number) =>
    ActionService.getListByUser(userId)
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                const apiResponse = action.payload;
                if (apiResponse.success) {
                    state.user = apiResponse.data.user;
                } else state.message = apiResponse.message;
            })
            .addCase(getActions.fulfilled, (state, action) => {
                const apiResponse = action.payload;
                if (apiResponse.success) {
                    state.actions = apiResponse.data.data;
                }
            })
            .addCase(login.rejected, (state) => {
                state.isLoading = false;
                state.message = i18n.t('translation.messageFailed');
            })
            .addCase(logout.fulfilled, (state) => {
                state.isLoading = false;
                state.user = null;
                state.actions = [];
                state.message = '';
            });
    },
});

export const { setUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectActions = (state: RootState) => state.auth.actions;

export default authSlice.reducer;
