import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { AUTH_KEYS } from 'constants/auth';
import { DEFAULT_VALUE } from 'constants/common';
import { includes, isEmpty } from 'lodash';
import { useLayoutEffect, useState } from 'react';
import { Circle, Disc, Home, Menu, Power, User as UserIcon, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logout } from 'slices/authSlice';
import Action from 'types/Action';
import User from 'types/User';
import { getIcon } from 'utils/actionIcon';
import { showToast } from 'utils/common';
import i18n from 'utils/i18n';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/localStorage';
import LanguageSwitch from './LanguageSwitch';

export default function Header({ listActions, user }: Readonly<{ listActions: Action[]; user: User | null }>) {
    //const [darkMode, setDarkMode] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [onScrollMenu, setOnScrollMenu] = useState(false);
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const [fixedMenu, setFixedMenu] = useState(!getLocalStorage('vnl_fixed_menu'));
    const [openActionIds, setOpenActionIds] = useState<number[]>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = i18n.language;
    useLayoutEffect(() => {
        document.body.classList.remove('blank-page');
        document.body.classList.add('menu-expanded');
    }, [listActions]);

    useLayoutEffect(() => {
        const menuElement = document.getElementById('main-menu-navigation');
        if (menuElement) {
            Array.from(menuElement.getElementsByClassName('nav-item')).forEach((element) => {
                Array.from(element.getElementsByTagName('a')).forEach((aEle) => {
                    if (aEle.classList.contains('active')) {
                        if (element.getElementsByClassName('menu-content').length > 0) {
                            aEle.closest('li')?.classList.add('active');
                            element.classList.add('sidebar-group-active');
                            element.classList.add('has-sub');
                            element.classList.add('open');
                        } else {
                            element.classList.add('active');
                        }
                        return false;
                    } else {
                        aEle.closest('li')?.classList.remove('active');
                    }
                });
            });
        }
    });

    // useLayoutEffect(() => {
    //     if (darkMode) {
    //         document.body.classList.add('dark-layout');
    //     } else {
    //         document.body.classList.remove('dark-layout');
    //     }
    // }, [darkMode]);

    useLayoutEffect(() => {
        if (fixedMenu) expandedMenuFn();
        else collapsedMenuFn();
        setLocalStorage('vnl_fixed_menu', fixedMenu ? '' : '1');
    }, [fixedMenu]);

    useLayoutEffect(() => {
        function changBodyClass() {
            if (window.innerWidth < 1200) {
                document.body.classList.add('vertical-overlay-menu');
                document.body.classList.add('menu-close');
                document.body.classList.remove('menu-collapsed');
                document.body.classList.remove('menu-expanded');
            } else {
                document.body.classList.remove('vertical-overlay-menu');
                document.body.classList.remove('menu-close');
            }
        }
        window.addEventListener('resize', changBodyClass);
        changBodyClass();
        return () => window.removeEventListener('resize', changBodyClass);
    }, []);

    const toggleMenuItem = (id: number) => {
        const items = [...openActionIds];
        if (includes(items, id)) {
            const index = items.indexOf(id);
            if (index > -1) items.splice(index, 1);
        } else items.push(id);
        setOpenActionIds(items);
    };

    const handleToggleMenu = () => {
        if (document.body.classList.contains('menu-close')) {
            document.body.classList.remove('menu-close');
            document.body.classList.add('menu-open');
            document.body.classList.remove('vertical-menu-modern');
        } else {
            document.body.classList.remove('menu-open');
            document.body.classList.add('menu-close');
            document.body.classList.add('vertical-menu-modern');
        }
    };

    const enterMenu = () => {
        if (!fixedMenu && window.innerWidth >= 1200) {
            expandedMenuFn();
        }
    };

    const leaveMenu = () => {
        if (!fixedMenu && window.innerWidth >= 1200) {
            collapsedMenuFn();
        }
    };

    const expandedMenuFn = () => {
        document.body.classList.remove('menu-collapsed');
        document.body.classList.add('menu-expanded');
        setCollapsedMenu(false);
    };

    const collapsedMenuFn = () => {
        document.body.classList.remove('menu-expanded');
        document.body.classList.add('menu-collapsed');
        setCollapsedMenu(true);
    };

    const userLogout = async () => {
        const logoutResult = await dispatch(logout());
        if (logout.fulfilled.match(logoutResult)) {
            if (logoutResult.payload.success || logoutResult.payload.error_code === 1) {
                removeLocalStorage(AUTH_KEYS.ACCESS_TOKEN);
            }
        } else {
            showToast(false);
        }
    };

    return (
        <>
            <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl">
                <div className="navbar-container d-flex content">
                    <div className="bookmark-wrapper d-flex align-items-center">
                        <ul className="nav navbar-nav d-xl-none">
                            <li className="nav-item">
                                <a className="nav-link menu-toggle" onClick={handleToggleMenu}>
                                    <Menu size={14} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ul className="nav navbar-nav align-items-center ms-auto">
                        <LanguageSwitch />
                        {/* <li className="nav-item d-none d-lg-block">
                            <a
                                className="nav-link nav-link-style"
                                onClick={() => setDarkMode((prevDarkMode) => !prevDarkMode)}
                            >
                                {darkMode ? <Sun size={14} /> : <Moon size={14} />}
                            </a>
                        </li> */}
                        <li className="nav-item dropdown dropdown-user">
                            <a
                                className="nav-link dropdown-toggle dropdown-user-link"
                                onClick={() => setShowUser((prevShow) => !prevShow)}
                            >
                                <div className="user-nav d-sm-flex d-none">
                                    <span className="user-name fw-bolder">{user?.name}</span>
                                    <span className="user-status">Admin</span>
                                </div>
                                <span className="avatar">
                                    <img
                                        className="round"
                                        src={user?.avatar ?? DEFAULT_VALUE.AVATAR}
                                        alt="avatar"
                                        height={40}
                                        width={40}
                                    />
                                    <span className="avatar-status-online" />
                                </span>
                            </a>
                            <div className={classNames('dropdown-menu dropdown-menu-end', { show: showUser })}>
                                <Link
                                    to={`/user/edit/${user?.id}`}
                                    className="dropdown-item"
                                    onClick={() => setShowUser((prevShow) => !prevShow)}
                                >
                                    <span className="me-50">
                                        <UserIcon size={14} />
                                    </span>
                                    {t('translation.personalPage')}
                                </Link>
                                <Link to="/" className="dropdown-item" onClick={userLogout}>
                                    <span className="me-50">
                                        <Power size={14} />
                                    </span>
                                    {t('translation.logout')}
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <div
                className={classNames('main-menu menu-fixed menu-light menu-accordion menu-shadow', {
                    expanded: !collapsedMenu,
                })}
                onMouseEnter={enterMenu}
                onMouseLeave={leaveMenu}
            >
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        <li className="nav-item me-auto">
                            <a className="navbar-brand" target="_blank" href="/">
                                <span className="brand-logo">
                                    <Home height={14} width={35} />
                                </span>
                                <h2 className="brand-text">Vinalend</h2>
                            </a>
                        </li>
                        <li className="nav-item nav-toggle">
                            <a className="nav-link modern-nav-toggle pe-0">
                                <span
                                    className="d-block d-xl-none text-primary toggle-icon font-medium-4"
                                    onClick={handleToggleMenu}
                                >
                                    <X size={14} />
                                </span>
                                <span
                                    className="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
                                    onClick={() => setFixedMenu((prevFixedMenu) => !prevFixedMenu)}
                                >
                                    {!fixedMenu && (
                                        <span>
                                            <Circle size={14} />
                                        </span>
                                    )}
                                    {fixedMenu && (
                                        <span>
                                            <Disc size={14} />
                                        </span>
                                    )}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={classNames('shadow-bottom', { onScrollMenu })} />
                <PerfectScrollbar
                    className="main-menu-content"
                    onScrollY={() => setOnScrollMenu((prevOnScrollMenu) => !prevOnScrollMenu)}
                >
                    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                        <li className="nav-item">
                            <NavLink to="/dashboard" className="d-flex align-items-center">
                                <Home size={14} />
                                <span className="menu-title text-truncate">Dashboards</span>
                            </NavLink>
                        </li>

                        {listActions
                            ?.filter((action) => action.displayOrder > 0)
                            .map((action) => (
                                <li
                                    className={classNames('nav-item', {
                                        'has-sub': !isEmpty(action.children),
                                        open: !isEmpty(action.children) && includes(openActionIds, action.id),
                                    })}
                                    key={action.id}
                                    onClick={() => toggleMenuItem(action.id!)}
                                >
                                    {!isEmpty(action.children) && (
                                        <>
                                            <a className="d-flex align-items-center">
                                                {getIcon(action.icon)}
                                                <span className="menu-title text-truncate">
                                                    {lang === 'en' ? action.nameEn : action.name}
                                                </span>
                                            </a>
                                            <ul className="menu-content">
                                                {action
                                                    .children!.filter((children) => children.displayOrder > 0)
                                                    .map((children) => (
                                                        <li key={children.id}>
                                                            <NavLink
                                                                to={children.url}
                                                                className="d-flex align-items-center"
                                                            >
                                                                {getIcon(children.icon)}
                                                                <span className="menu-title text-truncate">
                                                                    {lang === 'en' ? children.nameEn : children.name}
                                                                </span>
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </>
                                    )}
                                    {(!action.children || action.children.length === 0) && (
                                        <NavLink to={action.url} className="d-flex align-items-center">
                                            {getIcon(action.icon)}
                                            <span className="menu-title text-truncate">{action.name}</span>
                                        </NavLink>
                                    )}
                                </li>
                            ))}
                    </ul>
                </PerfectScrollbar>
            </div>
        </>
    );
}
