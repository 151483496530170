import { AUTH_KEYS } from 'constants/auth';
import i18n from 'i18next';
import en from 'language/en.json';
import vi from 'language/vi.json';
import { initReactI18next } from 'react-i18next';
import { getLocalStorage } from './localStorage';

const resources = {
    vi: {
        translation: vi,
    },
    en: {
        translation: en,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: getLocalStorage(AUTH_KEYS.LANGUAGE_CODE) || 'vi',

    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
