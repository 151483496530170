import { useAppDispatch, useAppSelector } from 'app/hooks';
import BoxErrorMessage from 'components/partials/BoxErrorMessage';
import Footer from 'components/partials/Footer';
import Header from 'components/partials/Header';
import { AUTH_KEYS } from 'constants/auth';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import UserService from 'services/UserService';
import { getActions, selectUser, setUser } from 'slices/authSlice';
import Action from 'types/Action';
import { checkPermission } from 'utils/common';
import { getLocalStorage } from 'utils/localStorage';

export function PrivateRoute({ children }: Readonly<{ children: JSX.Element }>) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [accessToken, setAccessToken] = useState(getLocalStorage(AUTH_KEYS.ACCESS_TOKEN));
    const user = useAppSelector(selectUser);
    const [listActions, setListActions] = useState<Action[]>([]);
    const [actionLoaded, setActionLoaded] = useState(false);
    const [canAccess, setCanAccess] = useState(false);

    useEffect(() => {
        const getUser = async () => {
            if (!user && accessToken) {
                const results = await UserService.getProfile();
                if (results.success) {
                    dispatch(setUser(results.data));
                } else {
                    setAccessToken('');
                }
            }
        };
        const getListActions = async () => {
            const actionResult = await dispatch(getActions(user?.id!));
            if (getActions.fulfilled.match(actionResult)) {
                if (actionResult.payload.data) {
                    setListActions(actionResult.payload.data.data);
                    setActionLoaded(true);
                    setCanAccess(
                        checkPermission(actionResult.payload.data.data, location.pathname, false, user?.id ?? 0)
                    );
                }
            }
        };
        getUser();
        if (user) getListActions();
    }, [user, location]);

    if (!isEmpty(accessToken)) {
        return (
            <>
                <Header listActions={listActions} user={user} />
                <div className="app-content content ">
                    <div className="content-overlay" />
                    <div className="header-navbar-shadow" />

                    {actionLoaded && canAccess && <div className="content-wrapper container-xxl p-0">{children}</div>}
                    {actionLoaded && !canAccess && (
                        <div className="content-wrapper container-xxl p-0">
                            <div className="content-body">
                                <BoxErrorMessage
                                    messageNotFound={'Tài nguyên được yêu cầu không được tìm thấy trên máy chủ.'}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </>
        );
    }
    return <Navigate to="/" state={{ from: location }} />;
}
