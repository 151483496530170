import { SelectOption } from 'types/common/Item';

export enum COMMON_MESSAGE {
    ERROR_MESSAGE = 'Có lỗi xảy ra trong quá trình thực hiện',
    EXPIRED_TOKEN = 'Phiên làm việc của bạn đã hết hạn, vui lòng đăng nhập lại',
    FIELD_REQUIRED = 'Trường này không được bỏ trống',
    FIELD_PASSWORD_LENGTH = 'Mật khẩu ít nhất 6 ký tự',
    FIELD_PASSWORD_MATCH = 'Mật khẩu không giống nhau',
    FIELD_EMAIL = 'Email không hợp lệ',
    FIELD_PHONE = 'Số điện thoại không hợp lệ',
    FIELD_NUMBER = 'Trường này phải là số',
    MIN_NUMBER = 'Giá trị bé nhất là ',
    MAX_NUMBER = 'Giá trị lớn nhất nhất là ',
    FIELD_DATE = 'Ngày tháng không hợp lệ',
    FIELD_REQUIRED_NAME = ' không được bỏ trống',
    FIELD_NUMBER_POSITIVE = 'Trường này phải lớn hơn 0',
}

export enum DEFAULT_VALUE {
    IMAGE = '/assets/images/vinalend/default.png',
    PRODUCT_IMAGE = '/assets/images/vinalend/no-product.png',
    AVATAR = '/assets/images/avatars/default.svg',
}

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL ?? 'http://vinalend-api.staging.savvycom.xyz/api/';

export const PAGINATION = {
    current_page: 1,
    per_page: 10,
    total: 0,
};

export const optionSelectDefault: SelectOption = { value: 0, label: '--' };
