import { DEFAULT_VALUE } from 'constants/common';
import { Link } from 'react-router-dom';

interface IProps {
    to: string;
    avatar: string;
    name: string;
}

export default function UserLink({ to, avatar, name }: Readonly<IProps>) {
    return (
        <Link to={to}>
            <img src={avatar ?? DEFAULT_VALUE.AVATAR} className="me-75" height="20" width="20" alt={name} />
            <span className="fw-bold">{name}</span>
        </Link>
    );
}
