import axios from 'axios';
import { AUTH_KEYS } from 'constants/auth';
import { REACT_APP_API_URL } from 'constants/common';
import { Language } from 'types/common/Item';
import { handleAxiosError } from 'utils/common';
import { getLocalStorage } from 'utils/localStorage';

const http = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
    },
});

http.interceptors.request.use(
    async function (config) {
        config.headers!.Authorization = `Bearer ${getLocalStorage(AUTH_KEYS.ACCESS_TOKEN)}`;
        config.headers!['Accept-Language'] = getLocalStorage(AUTH_KEYS.LANGUAGE_CODE, Language.VI);
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    async function (response) {
        if (response.data.result) {
            response.data.data = response.data.result;
        }
        return response;
    },
    function (error) {
        return handleAxiosError(error);
    }
);

export default http;
