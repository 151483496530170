import { isEmpty } from 'lodash';

interface IProps {
    messageNotFound: string;
}

export default function BoxErrorMessage({ messageNotFound }: IProps) {
    return !isEmpty(messageNotFound) ? (
        <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Thông báo</h4>
            <div className="alert-body">
                <p>{messageNotFound}</p>
            </div>
        </div>
    ) : (
        <></>
    );
}
