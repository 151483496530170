export interface ItemParam {
    id: number;
    name: string;
    className?: string;
    isDefault?: boolean;
}

export interface ItemCount extends ItemParam {
    count: number;
    isCustomer?: boolean;
    isAdmin?: boolean;
}

export interface ItemLink {
    id?: number;
    text: string;
    to: string;
    icon?: 'PLUS' | 'DOWNLOAD' | 'SEND' | 'UPLOAD';
    fnCallBack?: {
        actionMenu: (id: number) => void;
    };
}

export interface ItemId {
    id: number;
}

export interface ItemIds {
    ids: number[] | string[];
}

export interface ItemChecked {
    id: number;
    checked: boolean;
}

export interface ItemFile {
    id?: number;
    name: string;
    url: string;
    mineType?: string;
    fileSize?: number;
}

export interface FileParam {
    fileName: string;
    fileUrl: string;
    fileSize: number;
    mimeType: string;
}
export interface ImageParam {
    url: string;
}

export interface SelectOption {
    value: number;
    label: string;
}

export enum HierarchyReturnType {
    LIST = 1,
    PARENT = 2,
}

export enum Language {
    VI = 'vi',
    EN = 'en',
}

export enum ItemStatus {
    INACTIVE = 1,
    ACTIVE = 2,
    WAIT = 3,
    //CANCEL = 4,
}

export enum Currency {
    USD = 1,
    CNY = 2,
    EUR = 3,
    PLN = 4,
}

export enum OrderStatus {
    INACTIVE = 1,
    //ACCOUNTANT_ACTIVE = 2,
    ADMIN_ACTIVE = 3,
    WAIT = 4,
    //CANCEL = 5,
}

export enum ItemParamType {
    DEPARTMENT_TYPE = 'departmentType',
    GROUP = 'group',
    SEASON = 'season',
    TRADE_MARK = 'tradeMark',
    PAYMENT_TYPE = 'paymentType',
    TRANSPORT_TYPE = 'transportType',
}

export enum ListProductType {
    LIST = 1,
    QUANTITY = 2,
    STORE_QUANTITY = 3,
    INVENTORY_TURNOVER = 4,
    REVENUE = 5,
}

export const ItemStatusNames: ItemParam[] = [
    { id: ItemStatus.INACTIVE, name: 'Huỷ', className: 'badge badge-glow bg-danger' },
    { id: ItemStatus.ACTIVE, name: 'Đã duyệt', className: 'badge badge-glow bg-success' },
    { id: ItemStatus.WAIT, name: 'Chờ duyệt', className: 'badge badge-glow bg-warning' },
    //{ id: ItemStatus.CANCEL, name: 'Huỷ', className: 'badge badge-glow bg-dark' },
];

export const ItemParamTypeNames = [
    { id: ItemParamType.DEPARTMENT_TYPE, name: 'Loại đơn vị tổ chức' },
    { id: ItemParamType.GROUP, name: 'Nhóm khách hàng' },
    { id: ItemParamType.SEASON, name: 'Mùa' },
    { id: ItemParamType.TRADE_MARK, name: 'Thương hiệu' },
    { id: ItemParamType.PAYMENT_TYPE, name: 'Phương thức thanh toán' },
    { id: ItemParamType.TRANSPORT_TYPE, name: 'Phương thức giao hàng' },
];

export const CurrencyNames = [
    { id: Currency.USD, name: 'USD', className: 'badge badge-glow bg-success' },
    { id: Currency.CNY, name: 'CNY', className: 'badge badge-glow bg-danger' },
    { id: Currency.EUR, name: 'EUR', className: 'badge badge-glow bg-warning' },
    { id: Currency.PLN, name: 'PLN', className: 'badge badge-glow bg-info' },
];

export const OrderStatusNames: ItemParam[] = [
    { id: OrderStatus.INACTIVE, name: 'Huỷ', className: 'badge badge-glow bg-danger' },
    //{ id: OrderStatus.ACCOUNTANT_ACTIVE, name: 'Kế toán đã duyệt', className: 'badge badge-glow bg-info' },
    { id: OrderStatus.ADMIN_ACTIVE, name: 'Duyệt', className: 'badge badge-glow bg-success' },
    { id: OrderStatus.WAIT, name: 'Chờ duyệt', className: 'badge badge-glow bg-warning' },
    //{ id: OrderStatus.CANCEL, name: 'Huỷ', className: 'badge badge-glow bg-dark' },
];
