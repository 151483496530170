import UserLink from 'components/partials/UserLink';
import { AUTH_KEYS } from 'constants/auth';
import { find, get, includes, isEmpty, startsWith } from 'lodash';
import { ToastOptions, toast } from 'react-toastify';
import Action from 'types/Action';
import { AttributeWithValues } from 'types/Attribute';
import Product from 'types/Product';
import User from 'types/User';
import { Currency, ItemParam } from 'types/common/Item';
import i18n from './i18n';
import { removeLocalStorage } from './localStorage';

export const showToast = (success: boolean, message?: string) => {
    const options: ToastOptions<{}> = {
        position: toast.POSITION.TOP_RIGHT,
        toastId: Math.random(),
    };
    if (success) {
        if (message) {
            toast.success(message, options);
        }
    } else {
        toast.error(message ?? i18n.t('translation.messageFailed'), options);
    }
};

export const genTableIndex = (index: number, limit: number, currentPage: number) =>
    index + limit * (currentPage - 1) + 1;

export const getFieldHtml = (fields: ItemParam[], itemId: number) => (
    <span className={getFieldInArrayObject(fields, itemId, 'className')}>{getFieldInArrayObject(fields, itemId)}</span>
);

export const getFieldInArrayObject = (
    listObj: {}[],
    id: number | string,
    fieldName: string = 'name',
    defaultValue: string = ''
) => get(find(listObj, { id }), fieldName, defaultValue);

export const toggleModalOpen = (show: boolean) => {
    if (show) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }
};

export const closeModal = (changeShow: Function) => {
    if (changeShow) {
        changeShow(false);
    }
};

export const selectItem = (listItems: ItemParam[], noNoneOption: boolean = false, excludeIds: number[] = []) => {
    const selectOptions: JSX.Element[] = [];
    if (!noNoneOption) {
        selectOptions.push(
            <option key={0} value={0}>
                --
            </option>
        );
    }
    listItems
        .filter((item) => !includes(excludeIds, item.id))
        .forEach((item) => {
            selectOptions.push(
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            );
        });
    return selectOptions;
};

// tslint:disable-next-line
export const handleAxiosError = (error: any) => {
    if (error.response.status === 401 || error.response.status === 429) {
        removeLocalStorage(AUTH_KEYS.ACCESS_TOKEN);
        window.location.href = '/';
    } else {
        if (!isEmpty(error.response)) return error.response;
        return Promise.reject(error);
    }
};

const checkItemPermission = (actions: Action[], url: string) => {
    const action = find(actions, { url });
    let flag = !!action;
    if (!flag) {
        actions.forEach((item) => {
            if (!flag) {
                const child = find(item.children, { url });
                flag = !!child;
            }
        });
    }
    return flag;
};

export const checkPermission = (actions: Action[], pathName: string, isHidden: boolean, userId: number = 0) => {
    let url = pathName;
    let flag = false;
    if (isHidden) {
        const action = find(actions, { url: '/actionHidden' });
        if (action) {
            const child = find(action.children, { url });
            flag = !!child;
        }
    } else {
        if (includes(['/dashboard', '/action', `/user/edit/${userId}`], url)) {
            flag = true;
        } else if (
            startsWith(url, '/user/add/') ||
            includes(['/exchange/add/import', '/exchange/add/export', '/exchange/add/circulation'], url)
        ) {
            url = url.replace('/add', '');
        } else if (startsWith(url, '/user/edit/')) {
            ['/user/admin', '/user/customer', '/user/partner'].forEach((uri) => {
                if (!flag) flag = checkItemPermission(actions, uri);
            });
        } else if (startsWith(url, '/exchange/edit/') || startsWith(url, '/exchange/add/')) {
            ['/exchange/import', '/exchange/export', '/exchange/circulation'].forEach((uri) => {
                if (!flag) flag = checkItemPermission(actions, uri);
            });
        } else if (startsWith(url, '/store/')) {
            url = '/store';
        } else if (startsWith(url, '/product/')) {
            url = '/product';
        } else if (startsWith(url, '/import/')) {
            url = '/import';
        } else if (startsWith(url, '/order/')) {
            url = '/order';
        } else if (startsWith(url, '/returnOrder/')) {
            url = '/returnOrder';
        } else if (startsWith(url, '/bill/')) {
            url = '/bill';
        } else if (startsWith(url, '/transaction/')) {
            url = '/transaction';
        } else if (startsWith(url, '/printProduct/')) {
            url = '/printProduct';
        }
        if (!flag) flag = checkItemPermission(actions, url);
    }
    // tslint:disable-next-line:no-console
    console.log(url, flag);
    return flag;
};

export const genQueryString = (param: {}) => {
    // @ts-ignore
    const { properties, beginTime, endTime, ...rest } = param;
    let queryString = Object.entries(rest)
        .map(([key, value]) => {
            if (
                includes(['searchText', 'name', 'sku', 'style', 'address'], key) &&
                value &&
                (value as string).trim().length > 0
            ) {
                return `${key}[like]=${(value as string).trim()}`;
            }
            if (includes(['limit', 'page', 'searchWC'], key) && value) {
                return `${key}=${+value}`;
            }
            if (includes(['storeIds', 'id', 'productTypeIds'], key) && value) {
                return `${key}[in]=${(value as string).trim()}`;
            }
            if (value && +value > 0) {
                return `${key}[eq]=${+value}`;
            }
            if (value && value === 'null') {
                return `${key}[eq]=${value}`;
            }
            return '';
        })
        .join('&');
    if (properties) {
        queryString += `&properties[eq]=${JSON.stringify(properties)}`;
    }
    if (endTime) {
        queryString += `&endTime[lte]=${endTime}`;
    }
    if (beginTime) {
        queryString += `&beginTime[gte]=${beginTime}`;
    }
    return queryString ? `?${queryString}` : '';
};

export const getListAttributeName = (properties: {}, list: AttributeWithValues[]) => {
    let str = '';
    if (!isEmpty(properties) && list.length > 0) {
        const keys = Object.keys(properties);
        if (keys.length > 0) {
            keys.forEach((key, index) => {
                const value = find(list, { id: Number(key) });
                if (value?.value && value.value.length > 0) {
                    //@ts-ignore
                    const t = find(value.value, { id: Number(properties[key]) });
                    if (t) {
                        str += `${value.name}: ${t.value}${index < keys.length - 1 ? ', ' : ''}`;
                    }
                }
            });
        }
    }
    if (str) {
        return `(${str})`;
    } else {
        return '';
    }
};

export const getProductName = (listProducts: Product[], id: number, childId?: number) => {
    let a = <></>;
    const prod = find(listProducts, { id });
    if (prod) {
        if (prod.productChilds && prod.productChilds.length > 0 && childId) {
            const child = find(prod.productChilds, { id: childId });
            if (child) {
                a = (
                    <>
                        {child.image && <img className="me-75" height="20" width="20" src={child.image}></img>}
                        <span>
                            {prod.name} - {child.sku}
                        </span>
                    </>
                );
            }
        } else {
            a = (
                <>
                    {prod.image && <img className="me-75" height="20" width="20" src={prod.image}></img>}
                    <span>
                        {prod.name} - {prod.sku}
                    </span>
                </>
            );
        }
    }
    return a;
};

export const getExchangeRate = (currencyId: number | undefined, eurVal: number, cnyVal: number, plnVal: number) => {
    let val = 1;
    switch (currencyId) {
        case Currency.EUR:
            val = eurVal;
            break;
        case Currency.CNY:
            val = cnyVal;
            break;
        case Currency.PLN:
            val = plnVal;
            break;
        default:
            break;
    }
    return +val;
};

export const getUserLink = (users: User[], id: number) => {
    const u = find(users, { id });
    if (u) {
        return <UserLink to={`/user/edit/${u.id}`} avatar={u.avatar} name={u.name} />;
    }
    return <></>;
};
